<template>
  <div>
    <vueper-slides :arrows="false" :autoplay="true">
      <vueper-slide
        v-for="image in images"
        :key="image.id"
        :image="image.image"
      />
    </vueper-slides>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "slideshow",
  async mounted() {
    if (this.images.length === 0) {
      await this.fetchImages();
    }
  },
  computed: {
    ...mapState("slideshow", {
      processing: state => state.processing,
      images: state => state.images
    })
  },
  methods: {
    ...mapActions("slideshow", ["fetchImages"])
  }
};
</script>
