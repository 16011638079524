import axios from "axios";

export default {
  namespaced: true,
  state: {
    images: [],
    processing: false
  },
  mutations: {
    setProcessing(state, status) {
      state.processing = status;
    },
    setImages(state, images) {
      state.images = images;
    }
  },
  actions: {
    async fetchImages({ commit }) {
      commit("setProcessing", true);
      await axios
        .get("/slideshows")
        .then(response => {
          commit("setImages", response.data);
          commit("setProcessing", false);
        })
        .catch(error => {
          commit("setProcessing", false);
          commit("setError", error.response.data);
        });
    }
  },
  getters: {
    getById: state => id => {
      return state.images.find(item => item.id === id);
    }
  }
};
