import axios from "axios";

export default {
  namespaced: true,
  state: {
    projects: [],
    processing: false,
    error: null
  },
  mutations: {
    setProcessing(state, status) {
      state.processing = status;
    },
    setProjects(state, projects) {
      state.projects = projects;
    },
    setError(state, error) {
      state.error = error;
    }
  },
  actions: {
    async fetchProjects({ commit }, categoryId) {
      commit("setProcessing", true);
      await axios
        .get("/categories/" + categoryId + "/projects")
        .then(response => {
          commit("setProjects", response.data);
          commit("setProcessing", false);
        })
        .catch(error => {
          commit("setProcessing", false);
          commit("setError", error.response.data);
        });
    }
  },
  getters: {
    getProjectById: state => id => {
      return state.projects.find(item => item.id === id);
    }
  }
};
