<template>
  <div id="app" class="min-h-screen flex flex-col">
    <app-header>
      <navbar />
    </app-header>

    <slideshow />

    <div class="container mx-auto py-5">
      <router-view />
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "@/components/layout/Header.vue";
import AppFooter from "@/components/layout/Footer.vue";
import Navbar from "@/components/layout/Navbar.vue";
import Slideshow from "@/components/layout/Slideshow.vue";

export default {
  name: "arqcont",
  components: {
    AppHeader,
    Navbar,
    Slideshow,
    AppFooter
  }
};
</script>
