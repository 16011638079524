import axios from "axios";

export default {
  namespaced: true,
  state: {
    contents: [],
    customContent: [],
    processing: false,
    error: null
  },
  mutations: {
    setProcessing(state, status) {
      state.processing = status;
    },
    setContents(state, contents) {
      state.contents = contents;
    },
    setCustomContent(state, customContent) {
      state.customContent = customContent;
    },
    setError(state, error) {
      state.error = error;
    }
  },
  actions: {
    async fetchContents({ commit }) {
      commit("setProcessing", true);
      await axios
        .get("/contents")
        .then(response => {
          commit("setContents", response.data);
          commit("setProcessing", false);
        })
        .catch(error => {
          commit("setProcessing", false);
          commit("setError", error.response.data);
        });
    },
    async fetchCustomContent({ commit }) {
      commit("setProcessing", true);
      await axios
        .get("/contents/custom")
        .then(response => {
          commit("setCustomContent", response.data);
          commit("setProcessing", false);
        })
        .catch(error => {
          commit("setProcessing", false);
          commit("setError", error.response.data);
        });
    }
  },
  getters: {
    getContentById: state => id => {
      return state.contents.find(item => item.id === id);
    },
    getCustomContentByType: state => type => {
      return state.customContent.find(item => item.type === type);
    }
  }
};
