import Vue from "vue";
import axios from "axios";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueperSlides, VueperSlide } from "vueperslides";

// Libraries assets
import "vueperslides/dist/vueperslides.css";
import "@/assets/styles/app.css";

Vue.config.productionTip = false;

// Axios configuration
axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "/api" : "https://api.arqcontcr.com";

// Global components
Vue.component("vueper-slides", VueperSlides);
Vue.component("vueper-slide", VueperSlide);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
