<template>
  <footer class="bg-teal-900 text-white p-8">
    <div class="grid grid-cols-2 gap-4">
      <div>
        <h2 class="mb-2">Comuniquese con nosotros</h2>
        <hr class="mb-2" />
        <div v-if="phones" v-html="phones" />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "app-footer",
  data() {
    return {
      phones: null
    };
  },
  async mounted() {
    if (this.content.length === 0) {
      await this.fetchCustomContent();
    }

    this.phones = this.getCustomContentByType("telefonos").content;
  },
  computed: {
    ...mapState("content", {
      processing: state => state.processing,
      content: state => state.customContent
    }),
    ...mapGetters("content", ["getCustomContentByType"])
  },
  methods: {
    ...mapActions("content", ["fetchCustomContent"])
  }
};
</script>
