import Vue from "vue";
import Vuex from "vuex";
// import VuexPersist from "vuex-persist";

Vue.use(Vuex);

// const vuexLocal = new VuexPersist({
//   storage: window.sessionStorage
// });

import categories from "./categories";
import content from "./content";
import projects from "./projects";
import slideshow from "./slideshow";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    categories: categories,
    content: content,
    projects: projects,
    slideshow: slideshow
  }
  // plugins: [vuexLocal.plugin]
});
