<template>
  <div>
    <nav class="hidden md:flex xs:flex-col text-lg">
      <router-link
        :to="{ name: 'home' }"
        class="text-white hover:text-purple-300 py-3 px-6"
        >Inicio</router-link
      >

      <router-link
        :to="{ name: 'projects' }"
        class="text-white hover:text-purple-300 py-3 px-6"
        >Proyectos</router-link
      >
      <router-link
        :to="{ name: 'about' }"
        class="text-white hover:text-purple-300 py-3 px-6"
        >Sobre Nosotros</router-link
      >
      <a href="#" class="text-white hover:text-purple-300 py-3 px-6"
        >Contacto</a
      >
    </nav>
    <button class="flex md:hidden flex-col absolute top-0 right-0 p-4 mt-5">
      <span class="w-5 h-px mb-1 bg-blue-900"></span>
      <span class="w-5 h-px mb-1 bg-blue-900"></span>
      <span class="w-5 h-px mb-1 bg-blue-900"></span>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "navbar",
  async mounted() {
    if (this.categories.length === 0) {
      await this.fetchCategories();
    }
  },
  computed: {
    ...mapState("categories", {
      processing: state => state.processing,
      categories: state => state.categories
    })
  },
  methods: {
    ...mapActions("categories", ["fetchCategories"])
  }
};
</script>
