<template>
  <div>
    <header
      class="bg-teal-900 flex flex-col sm:flex-row items-center justify-between p-6 relative"
    >
      <h3 class="text-white">
        <span class="text-2xl font-bold">ArqCont</span> <small>S.A.</small>
      </h3>

      <slot />
    </header>
  </div>
  <!-- Header section start -->
  <!-- <header>
    <nav class="flex items-center justify-between flex-wrap bg-gray-900 p-6">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <span class="font-semibold text-xl tracking-tight">ArqCont</span>
      </div>

      <slot />
    </nav>
  </header> -->
</template>

<script>
export default {
  name: "app-header"
};
</script>

<style lang="scss" scoped></style>
